a.btn,button.btn,input.btn {
  min-width: $btn-min-width;
  min-height: $btn-min-height;
  box-shadow: $btn-box-shadow;
  border-radius: $border-radius;
  font: $btn-font-weight $btn-font-size $btn-font-family;
  line-height: $btn-line-height;
  cursor: pointer;
  color: $btn-default-color;
  background-color: $btn-default-bg;
  border: 1px solid $btn-default-border;
  @include hover-focus {
    color: $btn-default-color;
    background-color: $btn-default-hover;
    border-color: $btn-default-hover;
    outline:none;
  }
  &:active {
    outline:none;
  }
  &.btn-secondary {
    color: $btn-secondary-color;
    background-color: $btn-secondary-bg;
    border-color: $btn-secondary-border;
    @include hover-focus {
      color: $btn-secondary-color;
      background-color: $btn-secondary-hover;
      border-color: $btn-secondary-hover;
    }
  }
  &.btn-gray {
    color: $white;
    background-color: $gray;
    border-color: $gray;
    @include hover-focus {
      color: $white;
      background-color: $blue-dark;
      border-color: $blue-dark;
    }
  }
}
