@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"
.home-slideshow {
  background-color: $blue-dark;
  visibility: hidden;
  &.slick-initialized {
    visibility: visible;
  }
}
$slick-font-path: "./fonts/";
$slick-font-family: "Font Awesome 5 Free";
$slick-font-weight: 900;
$slick-font-size:     36px;
$slick-font-size-sm:  28px;
$slick-font-group: $slick-font-weight $slick-font-size-sm $slick-font-family;

$slick-button-size:       36px;
$slick-button-size-sm:    28px;
$slick-icon-distance:     -80px;
$slick-icon-distance-md:  -60px;
$slick-icon-distance-sm:  -45px;

$slick-loader-path: "./";
$slick-arrow-color: $gray-light;
$slick-arrow-color-hover: $gray;
$slick-arrow-bg: transparent;
$slick-prev-character: "\f0a8";
$slick-next-character: "\f0a9";
$slick-opacity-default: .9;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;

.slick-track {
  display: flex !important;
  align-items: center;
}
.slick-slide {
  height: inherit !important;
}

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}
.slick-slide {
  img {
    @extend .img-fluid;
    width: 100%;
  }
}


/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    z-index: 10;
    position: absolute;
    display: block;
    height: $slick-button-size;
    width: $slick-button-size;
    background-color: $slick-arrow-bg;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: 0px;
    outline: none;
    opacity: $slick-opacity-default;
    @include transition($link-transition);
    &:hover, &:focus {
        opacity: $slick-opacity-on-hover;
        outline: none;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
            color: $slick-arrow-color-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font: $slick-font-group;
        @include media-breakpoint-up(md) {
          font-size: $slick-font-size;
        }
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.slick-prev {
    left: $slick-icon-distance-sm;
    @include media-breakpoint-up(md) {
      left: $slick-icon-distance-md;
    }
    @include media-breakpoint-up(lg) {
      left: $slick-icon-distance;
    }
    [dir="rtl"] & {
        left: auto;
        right: $slick-icon-distance-sm+2;
        @include media-breakpoint-up(md) {
          right: $slick-icon-distance-md+2;
        }
        @include media-breakpoint-up(lg) {
          right: $slick-icon-distance+2;
        }
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: $slick-icon-distance-sm+2;
    @include media-breakpoint-up(md) {
      right: $slick-icon-distance-md+2;
    }
    @include media-breakpoint-up(lg) {
      right: $slick-icon-distance+2;
    }
    [dir="rtl"] & {
        left: $slick-icon-distance-sm;
        right: auto;
        @include media-breakpoint-up(md) {
          left: $slick-icon-distance-md;
        }
        @include media-breakpoint-up(lg) {
          left: $slick-icon-distance;
        }
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */
/*
.slick-dotted.slick-slider {
  margin-bottom: $grid-gutter-width*4;
}
*/

.slick-dots {
  position: absolute;
  bottom: $grid-gutter-width*3;
  .home & {
    bottom: $grid-gutter-width;
  }

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
  li {
    position: relative;

    display: inline-block;

    width: $grid-gutter-height*.8;
    height: $grid-gutter-height*.8;
    margin: 0 $grid-gutter-height*.5;
    padding: 0;

    cursor: pointer;
    button {
      font-size: 0;
      line-height: 0;

      display: block;

      border-radius: 50%;

      width: $grid-gutter-height*.8;
      height: $grid-gutter-height*.8;
      padding: 0;

      cursor: pointer;

      color: transparent;
      border: 0;
      outline: none;
      background-color: rgba($gray,.9);


      &:hover,&:focus {
        outline: none;
        background-color: $gray;
      }
    }
    &.slick-active {
      button {
        background-color: $white;
      }
    }
  }
}

.slick-lightbox{position:fixed;top:0;left:0;z-index:9999;width:100%;height:100%;background:black;-webkit-transition:opacity 0.5s ease;transition:opacity 0.5s ease}.slick-lightbox .slick-loading .slick-list{background-color:transparent}.slick-lightbox .slick-prev{left:15px}.slick-lightbox .slick-next{right:15px}.slick-lightbox-hide{opacity:0}.slick-lightbox-hide.slick-lightbox-ie{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";filter:alpha(opacity=0)}.slick-lightbox-hide-init{position:absolute;top:-9999px;opacity:0}.slick-lightbox-hide-init.slick-lightbox-ie{-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";filter:alpha(opacity=0)}.slick-lightbox-inner{position:fixed;top:0;left:0;width:100%;height:100%}.slick-lightbox-slick-item{text-align:center;overflow:hidden}.slick-lightbox-slick-item:before{content:'';display:inline-block;height:100%;vertical-align:middle;margin-right:-0.25em}.slick-caption-bottom .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption{position:absolute;bottom:0;left:0;text-align:center;width:100%;margin-bottom:20px}.slick-caption-dynamic .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption{display:block;text-align:center}.slick-lightbox-slick-item-inner{display:inline-block;vertical-align:middle;max-width:90%;max-height:90%}.slick-lightbox-slick-img{margin:0 auto;display:block;max-width:90%;max-height:90%}.slick-lightbox-slick-caption{margin:10px 0 0;color:white}.slick-lightbox-close{position:absolute;top:15px;right:15px;display:block;height:20px;width:20px;line-height:0;font-size:0;cursor:pointer;background:transparent;color:transparent;padding:0;border:none}.slick-lightbox-close:focus{outline:none}.slick-lightbox-close:before{font-family:"slick";font-size:20px;line-height:1;color:white;opacity:0.85;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:'×'}



