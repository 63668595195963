// Grid settings
$main-sm-columns:         12;
$sidebar-sm-columns:      4;
$grid-gutter-width-base:  30px;
$grid-gutter-width:       $grid-gutter-width-base;
$grid-gutter-height:      20px;

// Colors
$blue:        #006CD9;
$blue-light:  #80D6E6;
$blue-dark:   #2A4054;

$black:       #000;
$white:       #fff;

$shade1:      #8798A8;
$shade2:      #C5D1DB;
$shade3:      #E6ECF2;

$gray:            $shade1;
$gray-light:      $shade2;
$gray-lighter:    $shade3;
$gray-dark:       #5C6166;

$brand-primary:   $blue;
$brand-secondary: $blue-light;

$body-bg:         $gray-lighter;
$body-color:      $blue-dark;

// Links
$link-color:            $brand-primary;
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;
$link-transition:       all .15s ease-in-out;

// Fonts
$font-family-sans-serif: 'Rubik', "Segoe UI", "Helvetica Neue", Arial, sans-serif;
$font-family-monospace:  'IBM Plex Mono',Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

$font-weight-normal:  400;
$font-weight-bold:    700;
$font-weight-base:    $font-weight-normal;

$font-size-base: 1rem;    // 16px
$font-size-lg:   1.5rem;  // 24px
$font-size-sm:   .75rem;  // 12px

$font-size-h1:   2.5rem;  // 40px
$font-size-h2:   2.5rem;  // 40px
$font-size-h3:   1.5rem;  // 24px
$font-size-h4:   1.5rem;  // 24px
$font-size-h5:   1rem;    // 16px
$font-size-h6:   1rem;    // 16px

$headings-font-family:   $font-family-base;
$headings-font-weight:   $font-weight-bold;
$headings-line-height:   1.3;
$headings-color:         inherit;

$border-radius:          2px;
$border-radius-lg:       $border-radius;
$border-radius-sm:       $border-radius;
$border-width:           1px;
$border-color:           $gray-light;
$border-standard:        $border-width solid $border-color;

// Forms
$input-padding-x:                $grid-gutter-height*.5;
$input-padding-y:                $grid-gutter-height*.25;
$input-line-height:              1.1;
$input-height:                   $grid-gutter-height*2;
$input-bg:                       $white;
$input-color:                    $gray;
$input-border-color:             $border-color;
$input-btn-border-width:         $border-width;
$input-box-shadow:               none;
$input-border-radius:            $border-radius;
$input-border-radius-lg:         $border-radius-lg;
$input-border-radius-sm:         $border-radius-sm;
$input-bg-focus:                 $white;
$input-border-focus:             $blue;
$input-box-shadow-focus:         $input-box-shadow;
$input-color-focus:              $input-color;
$input-color-placeholder:        $gray-light;
$input-font-weight:              $font-weight-base;
$input-font-size:                $font-size-base;
$input-font-family:              $font-family-base;

// Buttons
$btn-padding-x:                  $grid-gutter-height;
$btn-padding-y:                  $grid-gutter-height*.35;
$btn-line-height:                1.5;
$btn-min-width:                  150px;
$btn-min-height:                 $input-height;
$btn-font-weight:                $font-weight-bold;
$btn-font-size:                  $font-size-base;
$btn-font-family:                $font-family-base;

$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;

$btn-default-color:              $white;
$btn-default-bg:                 $brand-primary;
$btn-default-border:             $brand-primary;
$btn-default-hover:              darken($brand-primary,10%);

$btn-primary-color:              $btn-default-color;
$btn-primary-bg:                 $btn-default-bg;
$btn-primary-border:             $btn-default-border;
$btn-primary-hover:              $btn-default-hover;

$btn-secondary-color:            $body-color;
$btn-secondary-bg:               $brand-secondary;
$btn-secondary-border:           $brand-secondary;
$btn-secondary-hover:            darken($brand-secondary,10%);


