.banner {
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.05);
  background-color: $blue-dark;
  color: $white;
  .brand {
    display: block;
    max-width: 160px;
    margin: $grid-gutter-width*.5 0;
    @include media-breakpoint-up(md) {
      max-width: 230px;
      margin: $grid-gutter-height*.7 0 $grid-gutter-height*.5;
    }
  }
  a {
    color: $white;
    @include hover-focus {
      color: $brand-secondary;
    }
  }
  .nav-col {
    align-self: center;
  }
  #menu-primary-navigation {
    flex-direction: row;
    justify-content: flex-end;
    .nav-item {
      align-self: center;
      .nav-link {
        line-height: 1.2;
        padding-left: $grid-gutter-height;
        padding-right: $grid-gutter-height;
        text-align: center;
      }
      &.active {
        .nav-link {
          color: $brand-secondary;
        }
      }
      &:first-child {
        .nav-link {
          padding-left: 0;
        }
      }
      &:last-child {
        .nav-link {
          padding-right: 0;
        }
      }
    }
    @include media-breakpoint-only(md) {
      font-size: 15px;
    }
  }
  .navbar-toggler {
    float: right;
    color: $white;
    font-size: 1.25rem;
    outline: none;
    background-color: $brand-primary;
    padding: 0;
    border: 0;
    border-radius: $grid-gutter-height;
    width: $grid-gutter-height*2;
    height: $grid-gutter-height*2;
    .fa-bars {
      display: none;
    }
    &.ready,&.collapsed {
      .fa-bars {
        display: inline-block;
      }
      .fa-times {
        display: none;
      }
    }
  }
  #mobileMenu {
    padding: 0 !important;
    max-height: none;
    background-color: $brand-primary;
    .navbar-nav {
      text-align: center;
      padding: 0;
      font-size: $font-size-lg;
      .nav-item {
        .nav-link {
          padding: $grid-gutter-width/2;
          color: $white;
        }
        &:first-child {
          margin-top: $grid-gutter-width;
        }
        &:last-child {
          margin-bottom: $grid-gutter-width;
        }
      }
    }
    div {
      padding: $grid-gutter-width 0 $grid-gutter-width*2;
      text-align: center;
      strong {
        display: block;
        margin-bottom: 1em;
      }
    }


    @include media-breakpoint-down(sm) {
      &.collapse {
        display: none !important;
        &.show {
          display: block !important;
        }
      }
    }
  }

}
