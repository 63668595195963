.page-header {
  text-align: center;
  margin: $grid-gutter-height*4 0 0;
  .home & {
    margin-top: 0;
  }
  h1 {
    margin-bottom: $grid-gutter-height*1.5;
  }
  &+div {
    text-align: center;
  }
}
.main {
  margin-bottom: $grid-gutter-height*1.5;
}
.block-home-content {
  background-color: $blue-dark;
  color: $white;
  padding: $grid-gutter-width*2 0 $grid-gutter-width;
  text-align: center;
}
.block-slideshow {
  margin-bottom: $grid-gutter-height*3;
  figcaption {
    margin-top: $grid-gutter-height*1.25;
    text-align: center;
    font-style: italic;
  }
}
.block-materials {
  margin: $grid-gutter-height 0 $grid-gutter-width;
  .material-row {
    justify-content: center;
  }
  .material-type {
    text-align: center;
    margin-bottom: $grid-gutter-width;
    @include media-breakpoint-up(lg) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
    h3 {
      color: $brand-primary;
      margin-bottom: $grid-gutter-height;
    }
  }
}
.block-capabilities {
  margin: $grid-gutter-height 0 $grid-gutter-width;
  .capability-row {
    justify-content: center;
  }
  .capability {
    margin-bottom: $grid-gutter-width;
    figure {
      margin-bottom: 0;
      background-color: $blue-dark;
      img {
        width: 100%;
        height: auto;
      }
    }
    article {
      background-color: $white;
      padding-bottom: $grid-gutter-width;
      text-align: center;
      height: 100%;
      h3,p,.gallery {
        margin: $grid-gutter-height*.75 0px 0px;
        padding: 0 $grid-gutter-height*.75;
      }
      h3 {
        color: $brand-primary;
      }
    }
/*
    text-align: center;
    margin-bottom: $grid-gutter-width;
    @include media-breakpoint-up(lg) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
    h3 {
      color: $brand-primary;
      margin-bottom: $grid-gutter-height;
    }
*/
  }
}

.block-cutsize,.block-callouts,.block-page-links {
  background-color: $blue-dark;
  color: $white;
  padding: $grid-gutter-height*3 0;
  text-align: center;
  h2 {
    margin-bottom: $grid-gutter-height*2.5;
  }
  .detail {

    figure {
      margin-bottom: $grid-gutter-height*1.5;
      img {
        height: $grid-gutter-width*1.5;
        width: auto;
      }
    }
    @include media-breakpoint-down(md) {
      margin-bottom: $grid-gutter-width*1.5;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      padding-left: $grid-gutter-width*1.5;
      padding-right: $grid-gutter-width*1.5;
    }
    @include media-breakpoint-up(xl) {
      padding-left: $grid-gutter-width*2;
      padding-right: $grid-gutter-width*2;
    }
    h3,h4 {
      margin-bottom: $grid-gutter-height;
    }
  }
}
.block-callouts {
  background-color: $blue;
}
.block-page-links {
  .detail {
    @include media-breakpoint-up(lg) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
    .btn {
      margin-top: $grid-gutter-width*.5;
    }
  }
}
.contacts-list {
  text-align: center;
  margin: $grid-gutter-width 0 $grid-gutter-width*2;
  padding: 0;
  list-style: none;
  li {
    margin: 0 $grid-gutter-height $grid-gutter-height*.5;
    display: inline-block;
    a {
      color: $body-color;
    }
    i {
      margin-right: $grid-gutter-height*.5;
      color: $brand-primary;
    }
  }
}
.contact-details {
  @include media-breakpoint-up(lg) {
    flex-direction: row-reverse;
  }
  &>div {
    text-align: center;
    margin-bottom: $grid-gutter-width*1.5;
    h4 {
      margin-bottom: $grid-gutter-width;
    }
    p {
      margin-bottom: $grid-gutter-width*1.5;
    }
    iframe {
      width: 100%;
    }
  }
}
