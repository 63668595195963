
img {
  @extend .img-fluid;
}
/***** TYPE *****/
h1,.h1 {
  letter-spacing: .02em;
  font-size: $font-size-h1;
}
h2,.h2 {
  letter-spacing: .02em;
  font-size: $font-size-h2;
  font-weight: $font-weight-base;
}
h3,.h3 {
  font-size: $font-size-h3;
}
h4,.h4 {
  font-size: $font-size-h4;
  font-weight: $font-weight-base;
}
h5,.h5 {
  font-size: $font-size-h5;
}
h6,.h6 {
  font-size: $font-size-h6;
  font-weight: $font-weight-base;
}

/***** LINKS *****/
a {
  @include transition($link-transition);
}
