// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
}
.form-control {
  font: $input-font-weight $input-font-size $input-font-family;
  border-radius: $input-border-radius;
  min-height: $input-height;
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
}

.gform_wrapper {
  ul.gform_fields {
    li.gfield {
      padding-right: 0 !important;
    }
  }
  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),textarea {
    @extend .form-control;
    padding: $input-padding-y $input-padding-x !important;
  }
  .top_label {
    .gfield_label {
      display: none !important;
    }
  }
  legend.gfield_label {
    display: none !important;
  }
  .gform_footer {
    padding: 0 !important;
    input.button, input[type=submit] {
      @extend .btn;
      width: 100% !important;
    }
  }
}
.gform_confirmation_wrapper  {
  background: $brand-primary;
  color: $white;
  padding: $grid-gutter-width*1.5 $grid-gutter-width;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
}
