.block-testimonials {
  padding: $grid-gutter-height*2 0 $grid-gutter-height*3;
  text-align: center;
  h3 {
    color: $brand-primary;
    margin-bottom: $grid-gutter-width;
  }
  .testimonials {
    background: #FFFFFF;
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    .testimonial {
      padding: $grid-gutter-height*2.5 $grid-gutter-height*4;
      font-style: italic;
      cite {
        display: block;
        color: $gray;
        font-weight: $font-weight-bold;
        font-style: normal;
      }
    }
  }
}

.content-info {
  padding: $grid-gutter-height*2.5 0;
  background-color: $blue-dark;
  color: $white;
  font-size: $font-size-sm;
  a {
    color: $white;
    text-decoration: none;
    @include hover-focus {
      color: $brand-secondary;
    }
  }
  h5 {
    color: $gray;
    margin-bottom: 1em;
  }
  .branding {
    margin-bottom: $grid-gutter-width*.5;
    img {
      max-width: 160px;
      margin-bottom: $grid-gutter-height*1.25;
      @include media-breakpoint-up(md) {
        margin-bottom: $grid-gutter-height*2.25;
      }
    }
  }
  dl {
    margin-bottom: $grid-gutter-width;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;      /* set the container width*/
    overflow: visible;
    dt {
      flex: 0 0 10%;
      overflow: hidden;
      margin-bottom: $grid-gutter-height*.65;
    }
    dd {
      flex:0 0 90%;
      margin:0 0 $grid-gutter-height*.65 auto;
      text-align: left;
      overflow: hidden;
    }
  }
  .nav-footer {
    margin-bottom: $grid-gutter-width;
    font-size: $font-size-base;
    .nav {
      padding: 0;
      .menu-item {
        margin-bottom: $grid-gutter-height*.5;
      }
    }
  }
}
